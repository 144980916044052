import React from "react";
import theme from "theme";
import { Theme, Text, Section, List, Link, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"gizlilik-politikasi"} />
		<Helmet>
			<title>
				Eclipse Events
			</title>
			<meta name={"description"} content={"Unutulmaz Deneyimler Yaratmak"} />
			<meta property={"og:title"} content={"Eclipse Events"} />
			<meta property={"og:description"} content={"Unutulmaz Deneyimler Yaratmak"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header1>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header1>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Gizlilik Politikası
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Bu Gizlilik Politikası, Eclipse Events olarak kişisel verilerinizi nasıl topladığımız, kullandığımız ve koruduğumuz konusunda sizi bilgilendirmek amacıyla hazırlanmıştır. Eclipse Events hizmetlerini kullanarak, bu politikanın şartlarını kabul etmiş olursunuz.
			</Text>
		</Section>
		<Section padding="40px 0" sm-padding="20px 0">
			<Override slot="SectionContent" align-items="flex-start" />
			<Text as="h3" font="--headline3" margin="20px 0">
				1. Toplanan Bilgiler
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Eclipse Events, hizmetlerimizi sunmak ve geliştirmek amacıyla çeşitli kişisel verileri toplayabilir. Topladığımız bilgiler şunları içerebilir:
			</Text>
			<List as="ul" font="--base" margin="10px 0 20px 20px">
				<Text as="li">
					İletişim Bilgileri: Adınız, e-posta adresiniz, telefon numaranız gibi bilgiler.
				</Text>
				<Text as="li">
					Etkinlik Bilgileri: Planladığınız etkinlikler hakkında verdiğiniz bilgiler.
				</Text>
				<Text as="li">
					Web Sitesi Kullanım Bilgileri: Çerezler ve benzeri teknolojiler aracılığıyla otomatik olarak toplanan bilgiler.
				</Text>
			</List>
			<Text as="h3" font="--headline3" margin="20px 0">
				2. Bilgilerin Kullanımı
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Topladığımız kişisel verileri şu amaçlarla kullanırız:
			</Text>
			<List as="ul" font="--base" margin="10px 0 20px 20px">
				<Text as="li">
					Hizmetlerimizi sunmak ve taleplerinizi karşılamak.
				</Text>
				<Text as="li">
					Müşteri desteği sağlamak ve iletişim kurmak.
				</Text>
				<Text as="li">
					Web sitemizi ve hizmetlerimizi iyileştirmek.
				</Text>
			</List>
			<Text as="h3" font="--headline3" margin="20px 0">
				3. Bilgilerin Paylaşımı
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Eclipse Events, kişisel verilerinizi yalnızca aşağıdaki durumlarda üçüncü taraflarla paylaşabilir:
			</Text>
			<List as="ul" font="--base" margin="10px 0 20px 20px">
				<Text as="li">
					Hizmetlerimizi sunmak için iş ortaklarımızla paylaşım yapılması gerektiğinde.
				</Text>
				<Text as="li">
					Yasal yükümlülüklere uymak amacıyla.
				</Text>
			</List>
			<Text as="h3" font="--headline3" margin="20px 0">
				4. Bilgilerin Saklanması ve Güvenliği
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Verilerinizin güvenliği bizim için önemlidir. Eclipse Events, kişisel verilerinizi korumak için uygun güvenlik önlemleri alır. Ancak, internet üzerinden yapılan hiçbir veri aktarımının tamamen güvenli olmadığını unutmayın.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				5. Çerezler ve İzleme Teknolojileri
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Web sitemizi ziyaret ettiğinizde, deneyiminizi iyileştirmek için çerezler kullanabiliriz. Çerezler, tarayıcınıza yerleştirilen ve web sitemizi nasıl kullandığınızı anlamamıza yardımcı olan küçük veri dosyalarıdır.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				6. Gizlilik Politikası Değişiklikleri
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Eclipse Events, bu gizlilik politikasını zaman zaman güncelleyebilir. Herhangi bir değişiklik yapıldığında, güncellenmiş politikayı web sitemizde yayınlayacağız ve gerektiğinde sizi bilgilendireceğiz.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				7. İletişim Bilgileri
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Gizlilik politikamız hakkında sorularınız varsa veya kişisel verilerinizle ilgili herhangi bir talepte bulunmak istiyorsanız, lütfen aşağıdaki iletişim bilgilerinden bize ulaşın:
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				**E-posta:** info@eventexcellence.com
			</Text>
		</Section>
		<Components.NewFooter>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text2" />
			<Override slot="link1" />
			<Box min-width="100px" min-height="auto" color="--light" align-self="center">
				<Link
					href="/sartlar-ve-kosullar"
					color="--light"
					margin="0px 1rem 0px 0px"
					text-decoration-line="underline"
					font="--headline5"
				>
					Şartlar ve Koşullar
				</Link>
				<Link href="/gizlilik-politikasi" color="--light" text-decoration-line="underline" font="--headline5">
					Gizlilik Politikası
				</Link>
			</Box>
		</Components.NewFooter>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});